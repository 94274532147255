<template>
  <div class="admin-wrapper">
    <h1>Inventar type</h1>
    <Items itemType="step" :isLoading="isLoading"></Items>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Items from '@/components/admin/Items.vue'

export default {
  name: 'Steps',
  data() {
    return {
      isLoading: false,
      selectedStep: '',
      isRemoving: false,
      removingId: '',
    }
  },
  components: {
    Items,
  },
  computed: {
    ...mapGetters(['optionalSteps']),
  },
  methods: {
    ...mapActions(['getOptionalSteps', 'deleteOptionalStep']),
    removing(id) {
      return this.removingId == id ? true : false
    },
    async onRemoveStep(id) {
      this.$confirm({
        message: `Er du sikker på at du vil slette denne inventar type?`,
        button: {
          yes: 'Ja',
          no: 'Nej',
        },
        callback: async confirm => {
          if (confirm) {
            this.isRemoving = true
            this.removingId = id
            await this.deleteOptionalStep(id).catch(() => this.deleteError())
            this.deleteSucces()
            this.isRemoving = false
          }
        },
      })
    },
    deleteSucces() {
      this.$notify({
        type: 'success',
        title: 'Succes! ',
        text:
          'Inventar typen er slettet <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#fff"/></svg>',
      })
    },
    deleteError() {
      this.$notify({
        type: 'error',
        title: 'Fejl!',
        text: 'Noget gik galt',
      })
    },
  },
  async mounted() {
    this.isLoading = true
    if (this.optionalSteps.length == 0) await this.getOptionalSteps()
    this.isLoading = false
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/admin/step.scss';
</style>
